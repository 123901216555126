body {
  display: grid;
  margin: 0;
  font-family: 'Roboto', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* @media only screen and (max-width: 920px) {
  body {
      max-width: var(--bs-breakpoint-lg) !important;
  }
} */


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --primaryColor: #013c64;
  --secundaryColor: #bdbdbd;
}
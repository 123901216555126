body {
  display: grid;
  margin: 0;
  font-family: 'Roboto', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* @media only screen and (max-width: 920px) {
  body {
      max-width: var(--bs-breakpoint-lg) !important;
  }
} */


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --primaryColor: #013c64;
  --secundaryColor: #bdbdbd;
}
.navBar {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    background-color: var(--primaryColor);
    width: 100%;
}

.header {
    position: fixed !important;
    top: 0
}

.footer {
    position: fixed !important;
    bottom: 0;
    flex-wrap: nowrap !important;
}

.PageNotFoundContainer {
    min-height: 92vh;
    background-color: #E8E8E8;
    padding: 10px;
}

@media only screen and (max-width: 1650px) {
    .PageNotFoundContainer {
        min-height: 92vh;
    }
}

/* @media only screen and (max-width: 1370px) and (max-height: 768px) {
    .divProduct {
        height: 55vh !important;
    }
}

@media only screen and (max-width: 1280px) {
    .divProduct {
        height: 70vh !important;
    }
} */


@media only screen and (max-width: 950px) {
    .container {
        width: 100% !important;
    }

    .form {
        width: 90% !important;
    }
}

@media only screen and (max-width: 500px) {
    .orderedContainer {
        margin-top: 8vh !important
    }

    .valueOrderedValues {
        font-size: 22px !important;
    }

    .spanTotalFooter {
        font-size: 22px !important;
    }

    .btnFinalizar {
        font-size: 20px !important;
    }
}

/* @media screen and (max-width: 430px) {
    .divProduct {
        height: 53vh !important;
    }
}

@media screen and (max-width: 414px) {
    .divProduct {
        height: 53vh !important;
    }
}

@media screen and (max-width: 390px) {
    .divProduct {
        height: 53vh !important;
    }

}

@media screen and (max-width: 385px) {
    .divProduct {
        height: 55vh !important;
    }

}

@media screen and (max-width: 375px) {
    .divProduct {
        height: 55vh !important;
    }
}

@media screen and (max-width: 360px) {
    .divProduct {
        height: 63vh !important;
    }
} */

.valueOrderedValues {
    background-color: transparent !important;
    border: none !important;
    font-size: 25px;
    color: var(--secundaryColor) !important;
    font-weight: bold !important;
    cursor: auto !important;
    width: 128px !important;
    margin-left: 5px;
}

.spanTotalFooter {
    color: white;
    font-size: 25px;
    font-weight: 400
}

.valueOrderedValues2 {
    background-color: transparent !important;
    border: none !important;
    font-size: 25px !important;
    color: var(--primaryColor) !important;
    font-weight: bold !important;
    cursor: auto !important;
    width: 100% !important;
    text-align: center;
    /* margin-left: 5px; */
}

.btnFinalizar {
    border-radius: 30px;
    color: var(--primaryColor);
    background-color: var(--secundaryColor);
    font-size: 24px;
    font-weight: bold;
    width: 160px;
    text-align: center;
    text-decoration: none;
}

.orderedContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow-y: auto;
    padding: 53px 0px 90px 0px;
}

.divProduct {
    background-color: white;
    border-radius: 25px;
    max-height: 80vh;
    width: 350px;
    box-shadow: 2px 2px 2px 4px rgba(0, 0, 0, 0.1);
    margin: 10px 0;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
}

.form {
    display: flex;
    flex-direction: column;
    width: 30%;
}

.productImage {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    width: 200px;
}

.productImg {
    width: 200px;
}

.productDatas {
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
    /* flex-wrap: wrap; */
    width: 290px;
    height: 100%;
    padding: 10px;
}

.productQntBtn {
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 40%;
}

.btnQnty {
    border-radius: 40px;
    color: white;
    font-weight: bold;
    background-color: var(--primaryColor);
    border: none;
    width: 50px;
    height: 50px;
    font-size: 20px;
}

.spanQnty {
    margin: 10px
}

.divQnty {
    width: 100%;
    margin: 10px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.btnAdd {
    background-color: var(--secundaryColor);
    border-radius: 25px;
    border: none;
    width: 120px;
    height: 35px;
    font-size: 18px;
    font-weight: 400;
    color: var(--primaryColor);
}

.divFooter {
    display: flex;
    height: 65px;
    justify-content: center;
    align-items: center;
}

.divDescription {
    /* overflow: hidden; */
    width: 100%;
    /* display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical; */
    font-size: 15px;
    color: #9D9D9D;
    height: 50%;
    text-align: center;
}

.divTitle {
    overflow: hidden;
    width: 100%;
    /* display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical; */
    font-size: 20px;
    color: #9D9D9D;
    font-weight: 700;
    text-align: center;
}

.titlePayment {
    font-size: 20px !important;
    font-weight: 900 !important;
    color: var(--primaryColor) !important;
    margin-bottom: 10px !important;
}

.inputPayment {
    margin-bottom: 10px;
    border-radius: 30px;
    height: 45px;
    border: solid 0.4px var(--primaryColor);
    padding: 0 10px
}

.radioPayment {
    color: var(--primaryColor) !important
}

.btnModal {
    width: 245px;
    height: 54px;
    border-radius: 25px;
    background-color: var(--secundaryColor);
    color: var(--primaryColor);
    font-size: 24px;
    font-weight: 900;
}

.divModal2 {
    font-size: 24px !important;
    font-weight: 400 !important;
    text-align: center !important;
    margin-bottom: 16px !important;
    line-height: 1.2 !important;
    color: var(--secundaryColor) !important;
}

.alertModal {
    position: fixed;
    width: 70vw;
    top: 10px;
    right: 10px;
}

.divProductDataBtn {
    display: flex;
    justify-content: start;
    align-items: center;
    height: 100%;
}

.divTextWhatsapp {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #C5C5C5;
    border-radius: 20px;
    width: 100%;
}

.inputTextWhatsapp {
    width: 90%;
    height: 10vw;
    resize: none;
    background: transparent;
    border: none;
    padding: 10px
}

.btnCopyLink {
    background-color: transparent;
    border: none;
}

.icoCopyLink {
    color: var(--primaryColor);
    font-size: 22px;
}
